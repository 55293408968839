export const state = {
  isEng: true,
};

const fetchLang = () => {
  const locale = navigator.language;
  const [str] = locale.split('-');
  if (str === 'fr') {
    state.isEng = false;
    document.title = 'Auto SAI | Bienvenue';
  } else {
    document.title = 'AUTO SAI | Welcome';
  }
};

const init = function () {
  fetchLang();
};

// const observe = function (entries, observer) {
//   entries.forEach(function (entry) {
//     if (entry.intersectionRatio > 0) {
//       const img = entry.target;
//       const src = img.dataset.src;
//       const srcset = img.dataset.srcset;

//       requestIdleCallback(function () {
//         if (srcset) {
//           img.srcset = srcset;
//         }

//         img.src = src;
//       });

//       observer.unobserve(img);
//     }
//   });
// };

// export const observer = new IntersectionObserver(observe, {});

init();
