import icons from 'url:../../img/sprite.svg';

export default class View {
  render(locale) {
    const titleStr = locale ? 'Auto SAI | Welcome' : 'Auto SAI | Bienvenue';
    document.title = titleStr;
    const markup = this._generateMarkup(locale);
    this.clear();
    this._parentElement.innerHTML = markup;
  }

  renderSpinner() {
    this._clear();
    this._parentElement.innerHTML = `
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    `;
  }

  clear() {
    this._parentElement.innerHTML = '';
  }

  fetchDate() {
    const date = new Date();
    return date;
  }
}
