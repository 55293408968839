import icons from 'url:../../img/sprite.svg';
import View from './View';

class FooterView extends View {
  _parentElement = document.querySelector('.section-footer');

  _generateMarkup(lang) {
    const isEng = lang;
    return `
    <div class="container gs_reveal gs_reveal_fromLeft">

      <div class="footer__outer-box">

        <svg class="logo-sai logo-sai--footer">
          <use href="${icons}#logo-sai"></use>
        </svg>

        <h2 class="u-mb-md">
        ${
          isEng
            ? 'Proudly serving automobilists in the Montreal area since 2011.'
            : 'Fier de servir les automobilistes dans la r&eacute;gion de Montr&eacute;al depuis 2011.'
        }
          
        </h2>

        <ul class="footer__left-list">
          <li><svg class="icon icon-pin">
              <use href="${icons}#icon-pin"></use>
            </svg>
            556 Michel Jasmin Ave, Dorval, Quebec, H9P 1C4
          </li>
          <li>
            <svg class="icon icon-mail">
              <use href="${icons}#icon-mail"></use>
            </svg>
            <a href="mailto:autosaiinc@gmail.com">&nbsp;
              autosaiinc@gmail.com</a><span>&nbsp;&larr;&nbsp;
              ${
                isEng
                  ? 'questions, comments or anything else?'
                  : 'questions, commentaires ou autre chose?'
              }
              </span>
          </li>
        </ul>

        <div class="footer__socials">
          <a class="footer__socials-link" href="https://www.facebook.com/Auto-sai-102231161667660" target="_blank" rel="noopener noreferrer">
            <svg class="icon icon-socials">
              <use xlink:href="${icons}#icon-fb"></use>
            </svg>
          </a>
          <a class="footer__socials-link" href="#">
            <svg class="icon icon-socials">
              <use xlink:href="${icons}#icon-twitter"></use>
            </svg>
          </a>
          <a class="footer__socials-link" href="#">
            <svg class="icon icon-socials">
              <use xlink:href="${icons}#icon-insta"></use>
            </svg>
          </a>
        </div>
      </div>


      <div class="footer__copyright">
        <span>&copy; Copyright <span class="year">${this.fetchDate().getFullYear()}</span> Auto Sai</span>
      </div>
    </div>
    `;
  }
}

export default new FooterView();
