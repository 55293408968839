import View from './View.js';
import icons from 'url:../../img/sprite.svg';
import svg404 from 'url:../../img/svg/404.svg';
import qrCodeImg from 'url:../../img/auto-sai_QR.png';

class RootView extends View {
  _parentElement = document.querySelector('#root');

  _generateMarkup(lang) {
    const isEng = lang;
    return `
    <header class="header">
    <div class="container">
      <div class="header__heading-box">
        <h1 class="header__heading-primary">
          ${
            isEng
              ? 'We are strongly committed in exceeding your expectations at a very competitive price, since 2011'
              : 'Nous sommes fortement engag&eacute;s &agrave; d&eacute;passer vos attentes, tout en ayant un prix tr&egrave;s comp&eacute;titif, depuis 2011'
          }
          <br>
          <span class="header__vector-line">
            <svg ${
              !isEng ? 'class="fr"' : ''
            }width="100%" height="100%" viewbox="0 0 202 29">
              <path d="M6 23C6 23 14.8837 19.0243 71.7395 11.9999C128.595 4.97555 197 5 197 5" stroke="white"
                stroke-width="10" stroke-linecap="round"></path>
            </svg>
          </span>
        </h1>
        <h1 class="header__heading-primary--sub">
          ${
            isEng
              ? 'Need an appointment today?'
              : "Besoin d'un rendez-vous aujourd'hui?"
          }
        </h1>
        <a class="btn btn-cta u-mt-md" href="https://calendly.com/autosaiinc/" rel="noreferrer">
          <span>
            <svg class="icon icon-phone">
              <use href="${icons}#icon-phone"></use>
            </svg>
            ${isEng ? 'Book Appointment' : 'Prendre un rendez-vous'}
          </span>
        </a>
      </div>
    </div>
    <div class="container__img-box">
      <ul class="slideshow">
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  </header>

  <main>
    <section class="section section-services">
      <div>
        <div class="section-heading__wrapper">
          <h2 class="section-heading gs_reveal gs_reveal_fromLeft">
            ${isEng ? 'OUR SERVICES' : 'NOS SERVICES'}
          </h2>
        </div>
        <span class="gs_reveal gs_reveal_fromLeft">
          ${
            isEng
              ? 'We have the tools and equipment to work on a wide range of vehicule makes and models.'
              : "Nous disposons des outils et de l'&eacute;quipement n&eacutec&eacute;ssaire pour travailler sur un large &eacute;ventail de marques et de mod&egrave;les."
          }
        </span>
      </div>
      <div class="container gs_reveal gs_reveal_fromLeft">
        <div class="services-card c1">
          <h3 class="services-card__heading">
            <svg class="icon icon-services">
              <use href="${icons}#icon-repair"></use>
            </svg>
            ${isEng ? 'Repairs' : 'Réparations'}
            
          </h3>
          <div class="services-card__list-box">
            <ul class="list">
              <li class="list__item">Suspension</li>
              <li class="list__item">${isEng ? 'Brakes' : 'Freins'}</li>
              <li class="list__item">${isEng ? 'Body work' : 'Carrosserie'}</li>
              <li class="list__item">${
                isEng ? 'Muffler' : 'Silencieux (muffler)'
              }</li>
            </ul>
          </div>
        </div>

        <div class="services-card c2">
          <h3 class="services-card__heading">
            <svg class="icon icon-services">
              <use href="${icons}#icon-oil"></use>
            </svg>
            Maintenance
          </h3>
          <div class="services-card__list-box">
            <ul class="list">
              <li class="list__item">${
                isEng ? 'Oil change' : "Changement d'huile"
              }</li>
              <li class="list__item">${
                isEng ? 'Transmission flush' : 'Rin&ccedil;age de transmission'
              }</li>
              <li class="list__item">${
                isEng
                  ? 'Coolant flush'
                  : 'Rin&ccedil;age du r&eacute;frig&eacute;rant'
              }</li>
              <li class="list__item">${
                isEng ? 'Rust proofing' : 'Antirouille'
              }</li>
            </ul>
          </div>
        </div>

        <div class="services-card c3">
          <h3 class="services-card__heading">
            <svg class="icon icon-services">
              <use href="${icons}#icon-tire"></use>
            </svg>
            ${isEng ? 'Tires' : 'Pneus'}
          </h3>
          <div class="services-card__list-box">
            <ul class="list">
              <li class="list__item">${
                isEng ? 'Mount and balance' : 'Montage et balancement'
              }</li>
              <li class="list__item">${
                isEng ? 'Tire storage' : 'Rangement de pneus'
              }</li>
              <li class="list__item">${
                isEng ? 'Repairs (patching)' : 'R&eacute;parations "patching"'
              }</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="section section-reviews">
      <div class="container">
        <div class="section-heading__wrapper gs_reveal gs_reveal_fromLeft">
          <h2 class="section-heading">${
            isEng ? 'WHAT OUR CUSTOMERS SAY' : 'CE QUE DISENT NOS CLIENTS'
          }</h2>
        </div>

        <div class="elfsight-app-a17f1f84-e41c-4232-8ad4-ea2ef13ba91e gs_reveal gs_reveal_fromRight"></div>
        <div class="tagembed-container" style=" width:100%;height:100%;overflow: auto;"><div class="tagembed-socialwall" data-wall-id="2880" view-url="https://widget.tagembed.com/2880?view"></div><script src="//widget.tagembed.com/embed.min.js" type="text/javascript"></script></div>
      </div>
    </section>

    <section class="section section-map">
      <div class="section-map__left-bg"></div>
      <div class="section-map__right-bg"></div>
      <div class="container">
        <div class="section-map__left-box">
          <h2 class="section-heading__light">
            <svg class="section-map__icon" width="100%" height="100%" viewbox="0 0 65 59">
              <use href="${icons}#icon-map"></use>
            </svg>
            ${
              isEng
                ? 'WHEN & WHERE TO FIND US'
                : 'QUAND ET O&Ugrave; NOUS TROUVER'
            }
            
          </h2>
          <div class="section-map__hours">
            <div class="section-map__weekdays">
              <table class="section-map__table">
                <tr>
                  <td>${isEng ? 'Monday:' : 'Lundi'}</td>
                  <td>8:00am -</td>
                  <td>&nbsp;5:00pm</td>
                </tr>
                <tr>
                  <td>${isEng ? 'Tuesday:' : 'Mardi'}</td>
                  <td>8:00am -</td>
                  <td>&nbsp;5:00pm</td>
                </tr>
                <tr>
                  <td>${isEng ? 'Wednesday:' : 'Mercredi'}</td>
                  <td>8:00am -</td>
                  <td>&nbsp;5:00pm</td>
                </tr>
                <tr>
                  <td>${isEng ? 'Thursday:' : 'Jeudi'}</td>
                  <td>8:00am -</td>
                  <td>&nbsp;5:00pm</td>
                </tr>
                <tr>
                  <td>${isEng ? 'Friday:' : 'Vendredi'}</td>
                  <td>8:00am -</td>
                  <td>&nbsp;5:00pm</td>
                </tr>
                <tr>
                  <td>${isEng ? 'Saturday:' : 'Samedi'}</td>
                  <td>8:00am -</td>
                  <td>&nbsp;2:00pm</td>
                </tr>
                <tr>
                  <td>${isEng ? 'Sunday:' : 'Dimanche'}</td>
                  <td>${isEng ? 'Closed' : 'Fermé'}</td>
                  <td></td>
                </tr>
              </table>
            </div>
          </div>
          <a class="btn btn-cta u-mb-lg u-mt-md" href="tel:5145383555" rel="noreferrer">
            <span>
              <svg class="icon icon-phone" viewbox="0 0 24 24">
                <use href="${icons}#icon-phone"></use>
              </svg>
              (514) 538-3555
            </span>
          </a>
        </div>

        <div class="section-map__google-map gs_reveal gs_reveal_fromRight">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2797.3183465185184!2d-73.77800868370494!3d45.483533740172874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc93dac1384f141%3A0x9ae4a4e44b72959d!2sAuto%20Sai%20Inc!5e0!3m2!1sen!2sca!4v1616419097849!5m2!1sen!2sca"
            width="100%" height="100%" style="border:0;
" allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
    </section>
  </main>
  <div class="overlay"></div>
    `;
  }
}

export default new RootView();
