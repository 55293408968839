'use strict';

// import 'core-js';

import navbarView from './views/navbarView.js';
import rootView from './views/rootView.js';
import footerView from './views/footerView.js';

import * as model from './model.js';

const controlNavbarView = function () {
  let state = model.state.isEng;
  const hash = window.location.hash.slice(1);
  if (hash === 'en') state = true;
  if (hash === 'fr') state = false;
  [navbarView, rootView, footerView].forEach((elem) => elem.render(state));

  gsap.utils.toArray('.gs_reveal').forEach(function (elem) {
    hide(elem); // assure that the element is hidden when scrolled into view

    ScrollTrigger.create({
      trigger: elem,
      start: 'top 80%',
      // markers: true,
      once: true, //set to true to play animations only once

      onEnter: function () {
        animateFrom(elem);
      }, // assure that the element is hidden when scrolled into view
    });
  });
};

// gsap animation #1 //////////////////////////////////////////

const hide = function (elem) {
  gsap.set(elem, { autoAlpha: 0 });
};

const animateFrom = function (elem, direction) {
  direction = direction || 1;
  let x = 0,
    y = direction * 100;
  if (elem.classList.contains('gs_reveal_fromLeft')) {
    x = -100;
    y = 0;
  } else if (elem.classList.contains('gs_reveal_fromRight')) {
    x = 100;
    y = 0;
  } else if (elem.classList.contains('gs_reveal_fromBottom')) {
    x = 0;
    y = 100;
  }

  elem.style.transform = `translate(${x}px, ${y}px')`;
  elem.style.opacity = '0';
  gsap.fromTo(
    elem,
    { x: x, y: y, autoAlpha: 0 },
    {
      duration: 2.25,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: 'expo',
      overwrite: 'auto',
    }
  );
};

document.addEventListener('DOMContentLoaded', function () {
  gsap.registerPlugin(ScrollTrigger);
});

const init = function () {
  navbarView.addHandlerRender(controlNavbarView);
};

init();
