import View from './View.js';
import icons from 'url:../../img/sprite.svg';
import logo from 'url:../../img/autosai_logo.svg';

class NavbarView extends View {
  _parentElement = document.querySelector('.navbar');
  _overlay;

  constructor() {
    super();
    this.addHandlerClick();
    this._overlay = document.querySelector('.overlay');
    // window.addEventListener('DOMContentLoaded', function () {
    //   _overlay = document.querySelector('.overlay');
    // });
  }

  addHandlerRender(handler) {
    ['hashchange', 'load'].forEach((ev) =>
      window.addEventListener(ev, handler)
    );
  }

  addHandlerClick() {
    this._parentElement.addEventListener(
      'click',
      this._handleNavbarClick.bind(this)
    );
  }

  _handleNavbarClick(e) {
    if (e.target.closest('.menu-ham')) this._toggleMenu();
  }

  _toggleMenu() {
    const menuHam = document.querySelector('.menu-ham');
    const navList = document.querySelector('.navbar__nav-list');

    menuHam.classList.toggle('active');
    navList.classList.toggle('navbar__nav-list--active');
    this._overlay.classList.toggle('overlay--active');
  }

  _generateMarkup(locale) {
    const isEng = locale;
    return `
    <div class="container">
    <div class="navbar__bg"></div>
    <div class="navbar__logo-box">
      <a href="${
        isEng ? '#en' : '#fr'
      }" rel="noreferrer"><img class="logo-sai" src="${logo}" alt="logo image"></a>
      <div>
        <span class="navbar__nav-sub-heading">
        ${
          isEng ? 'General mechanic' : 'M&eacute;canique g&eacute;n&eacute;rale'
        }        
        </span>
      </div>
    </div>
    <a href="#" class="menu-ham" rel="noreferrer">
      <span class="line line-1"></span>
      <span class="line line-2"></span>
      <span class="line line-3"></span>
    </a>
    <ul class="navbar__nav-list">
      <li class="navbar__nav-list--contact">
        <a href="mailto:autosaiinc@gmail.com" rel="noreferrer">
          <svg class="icon icon-mail--navbar">
            <use href="${icons}#icon-mail"></use>
          </svg>
          ${isEng ? 'CONTACT US' : 'CONTACTEZ-NOUS'}       
        </a>
      </li>
      <li class="navbar__nav-list--lang">
        <a class="navbar__nav-list--lang-link" href="${
          isEng ? '#fr' : '#en'
        }" rel="noreferrer">
          <svg class="icon icon-french">
              <use href="${icons}#icon-${isEng ? 'french' : 'eng'}"></use>
          </svg>${isEng ? 'FR' : 'EN'}</a>
      </li>
    </ul>
  </div>
    `;
  }
}

export default new NavbarView();
